
import { defineComponent, ref, reactive, inject, watch } from "vue";
import { useRoute } from "vue-router";
import api from "@/api/axios";

import SlotFooter from "@/components/common/SlotFooter.vue";
import Loading from "@/components/UI/Loading";
export default defineComponent({
  name: "standarddetail",
  components: {
    SlotFooter,
    Loading,
  },
  setup() {
    const route = useRoute();
    const id = ref(route.params.id);
    const standard = ref(null);
    const list = ref([]);
    const proList = ref([]);
    const industry = ref({});
    const isFav = ref(false);

    function getData(id: any) {
      standard.value = null;
      api
        .get("/M/Product/StandardDetail/" + id)
        .then((res): void => {
          const data = res.data.obj;
          standard.value = data.standard;
          industry.value = data.industry;
          isFav.value = data.isFav;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getData(id.value)
    function getNext(){
       api
        .get("/M/Product/StandardDetailRelation")
        .then((res): void => {
          const data = res.data.obj;
          list.value = data.list;
          proList.value = data.proList;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    getNext();

    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        if (to[1].id && !from[1].valid) {
          getData(to[1].id as string);
          getNext();
        }
      }
    );
    return {
      standard,
      list,
      industry,
      proList,
      id,
      loadOption,
      isFav
    };
  },
});
